import { Span, TypographyProps } from '@telus-uds/components-web'
import styled from 'styled-components'
import { VariantColor, VariantSize } from './TypographyVariantTypes'

type ParagraphProps = {
  textAlign?: TypographyProps['align']
  linesBetween?: number
}

/* istanbul ignore next */
const StyledParagraph = styled.div<ParagraphProps>`
  ${({ textAlign }) => (textAlign ? (textAlign === 'auto' ? `text-align: inherit;` : `text-align: ${textAlign};`) : '')}
  ${({ linesBetween = 1 }) => `
  margin-block-start: ${linesBetween}em;
  margin-block-end: ${linesBetween}em;
`}
  &:first-child {
    margin-block-start: 0em;
  }
  &:last-child {
    margin-block-end: 0em;
  }
`

interface RichTextParagraphProps extends TypographyProps {
  colour?: VariantColor
  inverse?: boolean
  size?: VariantSize
  linesBetween?: number
}

const RichTextParagraph = ({
  linesBetween,
  colour,
  inverse,
  size,
  align,
  children,
  testID,
}: RichTextParagraphProps) => (
  <StyledParagraph
    linesBetween={linesBetween}
    data-testid={testID}
    textAlign={align}
    data-test-id="rich-text-paragraph"
  >
    <Span
      variant={{
        size,
        colour,
        inverse,
      }}
    >
      {children}
    </Span>
  </StyledParagraph>
)

export default RichTextParagraph
